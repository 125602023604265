<template>
    <!-- Offcanvas menu -->
    <ion-menu side="start" content-id="main-content">
        <ion-header>
            <ion-toolbar>
                <ion-menu-toggle class="ion-float-end ion-padding-end">
                    <ion-icon :icon="close"/>
                </ion-menu-toggle>
                <ion-title>Main Navigation</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <!-- Main navigation section -->
            <ion-list>
                <ion-menu-toggle>
                    <ion-item button @click="navigateTo('/presentations')">Kurse / Präsentationen</ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle>
                    <ion-item button @click="navigateTo('/folder/foo')">Team</ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle>
                    <ion-item button @click="navigateTo('/editor')">Editor</ion-item>
                </ion-menu-toggle>
            </ion-list>
        </ion-content>
        <ion-footer id="menu-footer">
            <!-- Administrative section -->
            <ion-list>
                <ion-item @click="navigateTo('/profile')">Profil</ion-item>
                <ion-item @click="navigateTo('/payments')">Zahlungen</ion-item>
                <ion-item @click="navigateTo('/settings')">Einstellungen</ion-item>
            </ion-list>

            <!-- Call to action button -->
            <ion-button expand="full" @click="upgrade">Upgrade</ion-button>
        </ion-footer>
    </ion-menu>
</template>

<script setup>

import {
    IonHeader,
    IonFooter,
    IonTitle,
    IonToolbar,
    IonMenu,
    IonMenuToggle,
    IonList,
    IonItem,
    IonButton,
    IonContent,
    IonIcon
} from "@ionic/vue";
import {close} from "ionicons/icons"
</script>

<script>

export default {
    methods: {
        navigateTo(path) {
            // Implement navigation logic based on the selected page
            this.$router.push(path);

        },
        upgrade() {
            // Implement upgrade logic
            console.log('Upgrading...');
        },
    },
};
</script>

<style scoped>
#menu-footer{
    padding-bottom: 240px;
    /* Set background image styles */
    background: url('https://i.natgeofe.com/n/4f5aaece-3300-41a4-b2a8-ed2708a0a27c/domestic-dog_thumb_square.jpg') no-repeat center bottom / contain;
}
</style>
